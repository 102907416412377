import { CreateToastFnReturn } from '@chakra-ui/react';
import { batiment } from '@tsTypes/mySitesTypes';
import { sensor } from '@tsTypes/sensorType';
import Highcharts, { PointOptionsObject } from 'highcharts';

export function IntensityChart(
	sensor: sensor,
	batiment: batiment,
	mesures: [number, number][] | [number, number, number][],
	index: [number, number][],
	plotbands: Highcharts.AxisPlotBandsOptions[],
	toast: CreateToastFnReturn = null,
	simpleMode: boolean = false
): Highcharts.Options {
	// Coefficient pour passer des Ampères/minute aux Ampère/heure
	const VOLT = 230;

	let currentLastVal = 0;
	let indexIntensite: [number, number][] = [];

	mesures.forEach((mesure, i) => {
		if (i > 0) {
			if (!Number.isNaN(mesure[1])) {
				const deltaTemps = (mesure[0] - mesures[i - 1][0]) / 1000;

				currentLastVal += (mesure[1] * deltaTemps * VOLT) / 3600;

				// on divise par 1000 pour passer de Wh à kWh
				indexIntensite.push([mesure[0], currentLastVal / 1000]);
			}
		}
	});

	return {
		chart: {
			marginLeft: 75,
			zooming: {
				type: 'x',
				mouseWheel: {
					enabled: true,
				},
			},
			marginRight: 75,
			/*events: {
                load: !simpleMode ? function () {
                    const chart = this;
                    let diviser = false;

                    // Bouton de réinitialisation du zoom
                    chart.renderer
                        .button("Reset zoom", 0, 0, function () {
                            chart.zoomOut();
                        })
                        .attr({ zIndex: 999 })
                        .align({ align: "right", x: -100, y: 90 })
                        .add();
                } : null,
            },*/
		},
		accessibility: {
			enabled: false,
		},
		responsive: {
			rules: [
				{
					condition: {
						maxWidth: 910,
					},
					chartOptions: {
						title: {
							text: sensor.type_energie,
						},
					},
				},
			],
		},
		rangeSelector: {
			enabled: false,
		},
		navigator: {
			enabled: simpleMode ? false : true,
		},
		legend: {
			enabled: simpleMode ? false : true,
			itemStyle: {
				fontSize: '16px',
			},
		},
		tooltip: {
			valueDecimals: 2,
			xDateFormat: '%A %d %B %Y %H:%M:%S',
		},
		title: {
			text: `Intensité instantanée et cumulée ${batiment.nom_bat} - ${sensor.type_energie}`,
			y: 20,
			align: 'center',
			margin: 40,
			style: {
				fontSize: '20px',
			},
		},
		xAxis: {
			type: 'datetime',
			labels: {
				rotation: 0, // Désactive la rotation des étiquettes de l'axe X
				style: {
					fontSize: '10px', // Réduit la taille de police des étiquettes de l'axe X
				},
			},
			plotBands: plotbands,
		},
		yAxis: [
			{
				title: {
					text: `Intensités instantanées (${sensor.unite})`,
					align: 'high', // Aligne le titre de l'axe gauche en haut
					textAlign: 'left',
					y: -15, // Déplace le titre plus haut que le début du graphique
					rotation: 0, // Désactive la rotation du titre de l'axe gauche
					style: {
						color: '#628fc9', // Utilise la première couleur de la palette Highcharts,
						fontSize: '13px',
						fontWeight: 'bold',
						width: 250,
					},
				},
				labels: {
					format: `{value}`,
				},
				opposite: false,
				//endOnTick: true, // Permet à l'axe y de s'ajuster en fonction des données
			},
			{
				title: {
					text: `Somme des consommations (kWh)`,
					align: 'high', // Aligne le titre de l'axe gauche en haut
					textAlign: 'left',
					y: -15, // Déplace le titre plus haut que le début du graphique
					x: -210,
					rotation: 0, // Désactive la rotation du titre de l'axe gauche
					style: {
						color: 'purple', // Utilise la première couleur de la palette Highcharts,
						fontSize: '13px',
						fontWeight: 'bold',
						width: 250,
					},
				},
				labels: {
					format: `{value}`,
				},
				opposite: true,
				endOnTick: true, // Permet à l'axe y de s'ajuster en fonction des données
			},
		],
		boost: {
			enabled: true,
		},
		series: [
			{
				type: 'area',
				name: 'mesure instantanée',
				data: mesures as (number | [string | Date, number] | PointOptionsObject)[],
				fillColor: {
					linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1.4 },
					stops: [
						[0, Highcharts.getOptions().colors[0].toString()],
						[
							1,
							Highcharts.color(Highcharts.getOptions().colors[0])
								.setOpacity(0)
								.get('rgba')
								.toString(),
						],
					],
				},
				tooltip: {
					valueSuffix: `  A`,
				},
			},
			{
				type: 'line',
				name: 'Somme des consommations (kWh)',
				data: indexIntensite as (number | [string | Date, number] | PointOptionsObject)[],
				tooltip: {
					valueSuffix: ` kWh`,
				},
				color: 'purple',
				yAxis: 1,
			},
		],
	};
}
